@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  src: url('../../../next/public/media/SourceSansPro-Regular.ttf.woff2');
}

@font-face {
  font-family: 'Noto Sans JP';
  font-style: normal;
  font-weight: 400;
  src: url('../../../next/public/media/NotoSansJP-Regular.otf');
}

body {
  overflow: hidden;
  font-family: Helvetica, Arial, '游ゴシック体', 'YuGothic', 'メイリオ', 'Meiryo', sans-serif;
}

body.ssp {
  font-family: 'Source Sans Pro', Helvetica, Arial, '游ゴシック体', 'YuGothic', 'メイリオ', 'Meiryo',
    sans-serif;
}

body.sspnsjp {
  font-family: 'Source Sans Pro', 'Noto Sans JP', Helvetica, Arial, '游ゴシック体', 'YuGothic',
    'メイリオ', 'Meiryo', sans-serif;
}

button {
  border: none;
  background: none;
}

button:focus {
  outline: none;
}

a {
  text-decoration: none;
}

input {
  border: none;
  padding: 0;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}

input[type='date']::-webkit-inner-spin-button,
input[type='date']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}

input[type='datetime-local']::-webkit-inner-spin-button,
input[type='datetime-local']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}

input:focus {
  outline: none;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: inherit;
  outline: none;
}

.tippy-tooltip {
  overflow-wrap: break-word;
}
